<template>
  <div>
    <el-card class="card">
      <h1>
        {{ $t("subscription.title") }}
        <div class="subLogs">
          <button @click="viewLogs()">
            {{ $t("subscription.viewSubLogs") }}
          </button>
        </div>
      </h1>
      <el-row class="mt" style="text-align: center">
        <el-col :span="3" class="service">
          <h4 class="basic">{{ $t("subscription.basicServices") }}</h4>
          <h4 class="advertising">
            {{ $t("subscription.advertisingServices") }}
          </h4>
          <h4 class="marketing">{{ $t("subscription.overseasMarketing") }}</h4>
          <h4 class="dataService">{{ $t("subscription.dataService") }}</h4>
        </el-col>
        <!-- SERVICES-left -->
        <el-col :span="6" class="left">
          <div>
            <h3>{{ $t("subscription.bestPlan") }}</h3>
            <h3>{{ $t("subscription.SERVICES") }}</h3>
            <p>{{ $t("subscription.CATEGORIES") }}</p>
            <p>{{ $t("subscription.POSTPRODUCTS") }}</p>
            <p>{{ $t("subscription.INQUIRIES") }}</p>
            <p>{{ $t("subscription.RESPOND") }}</p>
            <p>{{ $t("subscription.SUBACCOUNT") }}</p>
            <p>{{ $t("subscription.PRODUCT") }}</p>
            <p>{{ $t("subscription.WEBS") }}</p>
            <p>{{ $t("subscription.AUDIT") }}</p>
            <p>{{ $t("subscription.LIVECHAT") }}</p>
            <p>{{ $t("subscription.SHOP") }}</p>
          </div>

          <div>
            <p>{{ $t("subscription.HOME") }}</p>
            <p>{{ $t("subscription.CATEGORY") }}</p>
            <p>{{ $t("subscription.SEARCH") }}</p>
            <p>{{ $t("subscription.RELATED") }}</p>
          </div>

          <div>
            <p>{{ $t("subscription.SOCIAL") }}</p>
            <p>{{ $t("subscription.GOOGLE") }}</p>
            <p>{{ $t("subscription.AFRICA") }}</p>
            <p>{{ $t("subscription.SUPPLIER") }}</p>
          </div>

          <div>
            <p>{{ $t("subscription.KEYWORDS") }}</p>
            <p>{{ $t("subscription.DATA") }}</p>
            <p>{{ $t("subscription.DOWNLOAD") }}</p>
            <p>-</p>
          </div>

          <!-- <p>{{ $t("subscription.post") }}</p>
          <p>{{ $t("subscription.showcased") }}</p>
          <p>{{ $t("subscription.respond") }}</p>
          <p>{{ $t("subscription.keywords") }}</p>
          <p>{{ $t("subscription.traffic") }}</p>
          <p>{{ $t("subscription.sub") }}</p>
          <p>{{ $t("subscription.keywordAdSpend") }}</p>
          <p>{{ $t("subscription.product") }}</p>
          <p>{{ $t("subscription.business") }}</p>
          <p>{{ $t("subscription.first") }}</p>
          <p>{{ $t("subscription.customer") }}</p>
          <p>{{ $t("subscription.performance") }}</p> -->
          <!-- <p>-</p> -->
        </el-col>
        <!-- SERVICES -->
        <el-col :span="5" class="center">
          <div>
            <h2>{{ $t("subscription.SILVER") }}</h2>
          </div>
          <div style="text-align: center">
            <!-- <p>
              <s class="originalPrice">$999</s>
              <span class="currentPrice">$599</span> /
              {{ $t("subscription.year") }}
            </p> -->
            <p>
              <s class="originalPrice">¥6399</s>
              <span class="currentPrice">¥3839.40</span> /
              {{ $t("subscription.year") }}
            </p>
          </div>
          <div>1</div>
          <div>{{ $t("subscription.UNLIMITED") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            10{{ $t("subscription.frequency") }}/{{ $t("subscription.month") }}
          </div>
          <div>3</div>
          <div>20</div>
          <div>{{ $t("subscription.AUTOMATIC") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>1</div>
          <div>2</div>
          <div>15</div>
          <div>15</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16_1.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16_1.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16_1.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>

          <!-- <el-button
            class="button"
            type="text"
            >{{ $t("subscription.payment") }}</el-button
          > -->

          <!-- <el-button
            class="active_button"
            disabled
            style="width: 100%; margin-left: -1px"
            type="text"
            >{{ $t("subscription.payment") }}</el-button
          > -->
          <pay-ment :amount="383940" />
        </el-col>
        <!-- GOLD -->
        <el-col :span="5" class="center">
          <div>
            <h2>{{ $t("subscription.GOLD") }}</h2>
          </div>
          <div style="text-align: center">
            <!-- <p>
              <s class="originalPrice">$1899</s>
              <span class="currentPrice">$1139</span> /
              {{ $t("subscription.year") }}
            </p> -->
            <p>
              <s class="originalPrice">¥11899</s>
              <span class="currentPrice">¥7139.40</span> /
              {{ $t("subscription.year") }}
            </p>
          </div>
          <div>1</div>
          <div>{{ $t("subscription.UNLIMITED") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>20/{{ $t("subscription.month") }}</div>
          <div>5</div>
          <div>40</div>
          <div>{{ $t("subscription.AUTOMATIC") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>3</div>
          <div>5</div>
          <div>15</div>
          <div>20</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>

          <!-- <el-button
            class="button"
            type="text"
            @click="payment(item._id)"
            v-if="!activeSubData"
            >{{ $t("subscription.payment") }}</el-button
          > -->

          <!-- <el-button class="active_button" type="text">{{
            $t("subscription.payment")
          }}</el-button> -->
          <pay-ment :amount="713940" />
        </el-col>
        <!-- DIAMOND -->
        <el-col :span="5" class="center">
          <div>
            <h2>{{ $t("subscription.DIAMOND") }}</h2>
          </div>
          <div style="text-align: center">
            <p>
              <s class="originalPrice">¥17699</s>
              <span class="currentPrice">¥10619.40</span> /
              {{ $t("subscription.year") }}
            </p>
          </div>
          <div>1</div>
          <div>{{ $t("subscription.UNLIMITED") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>30/{{ $t("subscription.month") }}</div>
          <div>8</div>
          <div>100</div>
          <div>{{ $t("subscription.AUTOMATIC") }}</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>6</div>
          <div>10</div>
          <div>30</div>
          <div>30</div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>
          <div>
            <img src="@/assets/Frame_16.png" />
          </div>

          <!-- <el-button
            class="button"
            type="text"
            @click="payment(item._id)"
            v-if="!activeSubData"
            >{{ $t("subscription.payment") }}</el-button
          > -->

          <!-- <el-button class="active_button" type="text">{{
            $t("subscription.payment")
          }}</el-button> -->
          <pay-ment :amount="1061940" />
        </el-col>
      </el-row>
    </el-card>

    <!-- Gold的编辑弹框 -->
    <el-dialog
      :visible="myVisible"
      width="38%"
      @close="myVisibles"
      style="text-align: center"
    >
      <h1 class="name">{{ this.planName }}</h1>
      <div class="box">
        <!-- <img :src="this.codeUrl" /> -->
        <!-- <div id="qrcode" ref="qrcode"></div> -->
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
        <!-- <vue-qr :logoSrc="this.codeUrl" text="xxx" :size="200"></vue-qr> -->
      </div>
      <div class="money">
        <span class="p1">{{ $t("subscription.amount") }}</span>
        <span class="p2">${{ this.price }}</span>
      </div>
      <!-- <el-button class="button1">{{$t("subscription.immediate")}}</el-button> -->
      <div class="bottom"></div>
    </el-dialog>
  </div>
</template>

<script>
import { renderData } from "@/helper/api";
import { createData } from "../../helper/api";
import PayMent from "@/components/PayMent";

import QRCode from "qrcodejs2";
export default {
  components: {
    PayMent,
  },
  data() {
    return {
      subList: [],
      sub_id: "",
      myVisible: false,
      activeSubData: {},
      planName: "",
      price: "",
      codeUrl: "",
    };
  },
  // components: {
  //           vueQr
  //       },
  created() {
    // this.qrcode();
  },
  mounted() {
    this.subLists();
  },
  methods: {
    subLists() {
      renderData("GET", "vendorplan").then((res) => {
        this.subList = res.data;
        // console.log(this.subList);
      });
    },
    viewLogs() {
      this.$router.push("subscriptionLogs");
    },
    payment(id) {
      this.myVisible = true;
      // console.log(id);
      createData("POST", "subscription", {
        planId: id,
      }).then((res) => {
        // console.log(res);
        this.price = res.data.price;
        this.codeUrl = res.data.codeUrl;
        this.planName = res.data.planName;

        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: this.codeUrl, // 需要转换为二维码的内容
          width: 100,
          height: 100,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        console.log(this.codeUrl, qrcode);
        this.$refs["qrCodeUrl"] = "";
      });
    },
    myVisibles() {
      this.myVisible = false;
      // this.$router.replace("/abc");
    },
  },
};
</script>

<style scoped>
.originalPrice {
  font-size: 15px;
  font-weight: 700;
  color: gray;
  margin-right: 5px;
}
.currentPrice {
  font-size: 20px;
  font-weight: 700;
}
h1 {
  font-size: 16px;
  margin-top: 10px;
}
.card {
  height: 1200px;
}
.center div {
  position: relative;
}
/* .right div {
  position: relative;
} */
img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
}
.mt {
  border: 1px solid #ffcc9d;
  border-radius: 10px;
  overflow: hidden;
}
.left h3 {
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #ffead7;
  overflow: hidden;
}
.left p {
  font-size: 14px;
  background: #ffcc9d;
  height: 40px;
  line-height: 40px;
  padding-left: 25px;
  font-weight: 700;
  margin-top: 1px;
  overflow: hidden;
  text-align: center;
}
.center div {
  min-height: 40px;
  line-height: 40px;
  font-size: 13px;
  border-right: 1px solid #ffcc9d;
  border-bottom: 1px solid #ffcc9d;
  text-align: center;
}
.center div img {
  margin-left: -10px;
}
.center h2 {
  font-size: 18px;
  text-align: center;
  overflow: hidden;
}
.center p {
  color: #ff0a0a;
  overflow: hidden;
}
/* .center .button {
  height: 40px;
  background: #ff0a0a;
  width: 96%;
  color: #fff;
  font-size: 15px;
  border-radius: unset;
  margin-left: 3px;
} */
.center .active_button {
  height: 40px;
  /* background: #ff0a0a; */
  background: gray;
  border-radius: 10px;
  width: 99%;
  margin-left: 3px;
  color: #fff;
  font-size: 15px;
  border-radius: unset;
}
/* .right div {
  height: 40px;
  line-height: 40px;
  padding-left: 25px;
  font-size: 13px;
  border-right: 1px solid #ffcc9d;
  border-bottom: 1px solid #ffcc9d;
}
.right h2 {
  font-size: 18px;
}
.right p {
  color: #ff0a0a;
} */
/* .right .button {
  height: 40px;
  background: #ff0a0a;
  width: 100%;
  color: #fff;
  font-size: 15px;
  margin-left: 1px;
} */
.radio {
  margin-left: 40px;
  font-size: 13px;
}
.box {
  margin-top: 30px;
}
.select {
  margin-left: 40px;
  font-size: 13px;
  height: 41px;
}
.select .select1 {
  width: 501px;
}
.money {
  margin-top: 38px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}
.money .p1 {
  color: #000;
  text-align: center;
}
.money .p2 {
  color: #ff0a0a;
  text-align: center;
}
/* .button1 {
  background: #ff0a0a;
  font-size: 15px;
  width: 218px;
  height: 52px;
  color: #fff;
  border-radius: 10px;
  margin-top: 55px;
  margin-left: 2%;
} */
.bottom {
  margin-top: 83px;
}
.subLogs {
  float: right;
}
.subLogs button {
  background: #ff0a0a;
  font-size: 15px;
  color: #fff;
  border-radius: 5px;
  margin-left: 0px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.name {
  font-size: 25px;
  text-align: left;
  margin-left: 5%;
  color: #ff0a0a;
}
.qrcode {
  display: inline-block;
}
.qrcode img {
  width: 300px;
  height: 300px;
}
/* .qrcode img {
  
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
} */
.service {
  font-size: 13px;
  height: 982px;
}
.service .basic {
  background: #fae1e0;
  height: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service .advertising {
  height: 164px;
  background: #edfae0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service .marketing {
  height: 164px;
  background: #e0eafa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service .dataService {
  height: 164px;
  background: #faf1e0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
