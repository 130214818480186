<template>
  <div>
    <el-button
      v-loading="loading"
      class="wechatpaybtn"
      @click="handlePay"
      type="primary"
      round
    >
      <i class="sokogate icon-wechat-pay"></i>
      <span>{{ $t("payment.wechatpay") }}</span>
    </el-button>
    <el-dialog
      v-loading="dialogLoading"
      :title="$t('payment.wechatpay')"
      :visible="dialogVisible"
      :lock-scroll="true"
      :append-to-body="true"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      width="450px"
      :show-close="false"
    >
      <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      <h3 v-if="payInfo && payInfo.ID" class="payment">
        {{ $t("payment.amount") }}
        <span class="price">
          <span class="price-unit">¥</span>
          {{ formatPrice(payInfo.payerTotal) }}
        </span>
      </h3>
      <h4 class="payment-tips">{{ $t("payment.wechatpaytips") }}</h4>
      <template v-slot:footer class="dialog-footer">
        <el-button @click="handleConfirmClose">{{
          $t("payment.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleSuccess">{{
          $t("payment.success")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { GetWechatPay, GetPayResult } from "@/utils/api";
import QRCode from "qrcodejs2";

export default {
  props: {
    amount: {
      type: Number,
      deflut: 1,
    },
  },
  data() {
    return {
      loading: false,
      dialogLoading: false,
      dialogVisible: false,
      payInfo: null,
    };
  },
  methods: {
    formatPrice(price) {
      return (price / 100).toFixed(2);
    },
    handleOpen(url) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 300,
          height: 300,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        console.log("qrcode:", qrcode);
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.qrCodeUrl.innerHTML = "";
    },
    handleSuccess() {
      this.getPayResult();
    },
    handleConfirmClose() {
      this.$confirm(
        this.$t("payment.confirmclosetips"),
        this.$t("common.tips"),
        {
          confirmButtonText: this.$t("payment.cancel"),
          cancelButtonText: this.$t("payment.returntopay"),
          type: "warning",
        }
      ).then(() => {
        this.handleClose();
      });
    },
    getPayResult() {
      this.dialogLoading = true;
      GetPayResult({
        id: this.payInfo.ID,
      })
        .then((res) => {
          console.log("GetPayResult-res", res);
          if (res.data.ID) {
            switch (res.data.status) {
              case 1:
                //  待支付
                this.$message({
                  message: this.$t("payment.unpaid"),
                  type: "warning",
                });
                break;

              case 2:
                //  已关闭
                this.$message.error(this.$t("payment.payclosed"));
                this.handleClose();
                break;

              case 3:
                //  已支付
                this.$message({
                  message: this.$t("payment.success"),
                  type: "success",
                });
                this.handleClose();
                break;

              default:
                break;
            }
          }
          this.dialogLoading = false;
        })
        .catch((err) => {
          console.log("GetPayResult-err", err);
          this.dialogLoading = false;
        });
    },
    handlePay() {
      this.loading = true;
      GetWechatPay({
        payMethod: 201,
        type: 101,
        total: this.amount,
      })
        .then((res) => {
          console.log("GetWechatPay-res", res);
          if (res.data.codeUrl) {
            this.handleOpen(res.data.codeUrl);
            this.payInfo = res.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("GetWechatPay-err", err);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wechatpaybtn {
  margin-top: 10px;
  background-color: #4caf50;
  padding: 4px 20px;
  min-width: 100px;

  span {
    font-size: 18px;
    line-height: 28px;
  }

  .sokogate {
    font-size: 18px;
    margin-right: 10px;
  }
}
.qrcode {
  width: 300px;
  height: 300px;
  display: block;
  margin: 0 auto;
}
.payment {
  padding: 10px 0;
  text-align: center;
  font-size: 20px;

  &-tips {
    text-align: center;
  }
}
.price {
  font-size: 24px;
  color: #f60;
  margin-left: 10px;
  letter-spacing: 2px;

  &-unit {
    font-size: 14px;
  }
}
</style>